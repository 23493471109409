import { createWebHistory, createRouter } from "vue-router";
import DifferentialLiberationSection from "../views/DifferentialLiberationSection.vue";
import FluidsDataStudio from "../views/FluidsDataStudio.vue";
import FluidsDataStudioOne from "../views/FluidsDataStudioOne.vue";
import FluidsDataStudioTwo from "../views/FluidsDataStudioTwo.vue";
import ConstantCompositionExpansion from "../views/ConstantCompositionExpansion";
import MultiStageSeparatorTest from "../views/MultiStageSeparatorTest";
import CvdTest from "../views/CvdTest";
import OilViscosity from "../views/OilViscosity";
import Composition from "../views/Composition";
import store from "@/store";
// AI / ML Modelling
import AIMLModelling from "../views/AIMLModelling/AIMLModelling";
import DataInputs from "../views/AIMLModelling/DataInputs";
import UploadReports from "../views/AIMLModelling/UploadReports";
import SelectData from "../views/AIMLModelling/SelectData";
import DataPreview from "../views/AIMLModelling/DataPreview";
import AIMLModels from "../views/AIMLModelling/AIMLModels";
import SupervisedMLModels from "../views/AIMLModelling/SupervisedMLModels";
import FluidsdataAIModel from "../views/AIMLModelling/FluidsdataAIModel";
import BlackOilTable from "../views/AIMLModelling/BlackOilTable";
import SelectedPVTReport from "../views/AIMLModelling/SelectedPVTReport";
import FullPVTReport from "../views/AIMLModelling/FullPVTReport";
import ManualDataEntry from "../views/AIMLModelling/ManualDataEntry";

// SingleSampleFlow
import Assets from "../views/SingleSampleFlow/Assets";
import Fields from "../views/SingleSampleFlow/Fields";
import Wells from "../views/SingleSampleFlow/Wells";
import Samples from "../views/SingleSampleFlow/Samples";
import OilTest from "../views/SingleSampleFlow/OilTest";
import GasTest from "../views/SingleSampleFlow/GasTest";

// Login
import Login from "../views/Auth/Login"

// Exploratory Data Analysis
import DataInventoryAnalysis from "../views/ExploratoryDataAnalysis/DataInventoryAnalysis";
import DataExploration from "../views/DataExploration";
import CompositionChart from "../views/ExploratoryDataAnalysis/CompositionChart";
import CorrelationAnalysis from "../views/ExploratoryDataAnalysis/CorrelationAnalysis";
import CorrelationCoefficient from "../views/ExploratoryDataAnalysis/CorrelationCoefficient";
import FluidsdataInsight from "../views/ExploratoryDataAnalysis/FluidsdataInsight";
import axios from "axios";
// Dashboard
import Main from "../views/Dashboard/Main"
import Board from "../views/Dashboard/Board"

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    localStorage.removeItem("authToken");
    return next();
  } else {
    return next("/assets");
  }
};
const auth = async (to, from, next) => {
  if (localStorage.getItem("authToken") && localStorage.getItem('tenantId')) {
    try {
      return next(); // Proceed to the requested route without making an API call
    } catch (error) {
      console.error("Error fetching user data:", error);
      localStorage.removeItem("authToken"); // Clear the token if the API fails
      return next("/login"); // Redirect to login
    }
  } else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
};

const routes = [
  {
    path: "/",
    beforeEnter: guest,
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: Login
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?/dltest/:id",
    name: "DifferentialLiberationSection",
    beforeEnter: auth,
    component: DifferentialLiberationSection,
  },
  {
    path: "/Fluidsdata-studio",
    name: "FluidsDataStudio",
    beforeEnter: auth,
    component: FluidsDataStudio,
  },
  {
    path: "/Fluidsdata-studio-one",
    name: "FluidsDataStudioOne",
    beforeEnter: auth,
    component: FluidsDataStudioOne,
  },
  {
    path: "/Fluidsdata-studio-two",
    name: "FluidsDataStudioTwo",
    beforeEnter: auth,
    component: FluidsDataStudioTwo,
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?/ccetest/:id",
    name: "ConstantCompositionExpansion",
    beforeEnter: auth,
    component: ConstantCompositionExpansion,
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?/separatortest/:id",
    name: "MultiStageSeparatorTest",
    beforeEnter: auth,
    component: MultiStageSeparatorTest,
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?/viscositytest/:id",
    name: "OilViscosity",
    beforeEnter: auth,
    component: OilViscosity,
  },
  {
    path: "/assets",
    name: "Assets",
    beforeEnter: auth,
    component: Assets,
  },
  {
    path: '/assets/:asset',
    name: "Fields",
    beforeEnter: auth,
    component: Fields,
  },
  {
    path: "/assets/:asset/:field",
    name: "Wells",
    beforeEnter: auth,
    component: Wells,
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?",
    name: "Samples",
    beforeEnter: auth,
    component: Samples,
  },
  {
    path: "/oilTest",
    name: "OilTest",
    beforeEnter: auth,
    component: OilTest,
  },
  {
    path: "/gasTest",
    name: "GasTest",
    beforeEnter: auth,
    component: GasTest,
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?/composition/:id",
    name: "Composition",
    beforeEnter: auth,
    component: Composition,
  },
  {
    path: "/assets/:asset/:field/:well/:sampleId?/cvdtest/:id",
    name: "CvdTest",
    beforeEnter: auth,
    component: CvdTest,
  },
  {
    path: "/ai-ml-modelling",
    name: "AIMLModelling",
    beforeEnter: auth,
    component: AIMLModelling,
  },
  {
    path: "/ai-ml-modelling/define-inputs",
    name: "DataInputs",
    beforeEnter: auth,
    component: DataInputs,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports",
    name: "UploadReports",
    beforeEnter: auth,
    component: UploadReports,
  },
  {
    path: "/ai-ml-modelling/define-inputs/select-from-database",
    name: "SelectData",
    beforeEnter: auth,
    component: SelectData,
  },
  {
    path: "/ai-ml-modelling/define-inputs/data-preview",
    name: "DataPreview",
    beforeEnter: auth,
    component: DataPreview,
  },
  {
    path: "/ai-ml-modelling/define-inputs/data-preview/ai-ml-models",
    name: "AIMLModels",
    beforeEnter: auth,
    component: AIMLModels,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression",
    name: "SupervisedMLModels",
    beforeEnter: auth,
    component: SupervisedMLModels,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model",
    name: "FluidsdataAIModel",
    beforeEnter: auth,
    component: FluidsdataAIModel,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/black-oil-table",
    name: "BlackOilTable",
    beforeEnter: auth,
    component: BlackOilTable,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/selected-pvt-report",
    name: "SelectedPVTReport",
    beforeEnter: auth,
    component: SelectedPVTReport,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Supervisod-ml-models/support-vector-regression/full-pvt-report",
    name: "FullPVTReport",
    beforeEnter: auth,
    component: FullPVTReport,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/black-oil-table",
    name: "FluidsdataBlackOilTable",
    beforeEnter: auth,
    component: BlackOilTable,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/selected-pvt-report",
    name: "FluidsdataSelectedPVTReport",
    beforeEnter: auth,
    component: SelectedPVTReport,
  },
  {
    path: "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model/full-pvt-report",
    name: "FluidsdataFullPVTReport",
    beforeEnter: auth,
    component: FullPVTReport,
  },
  {
    path: "/ai-ml-modelling/define-inputs/manual-data-entry",
    name: "ManualDataEntry",
    beforeEnter: auth,
    component: ManualDataEntry,
  },

  {
    path: "/data-management",
    name: "DataManagement",
    beforeEnter: auth,
    component: DataExploration,
  },

  // EDA Routes
  {
    path: "/data-inventory-analysis",
    name: "DataInventoryAnalysis",
    beforeEnter: auth,
    component: DataInventoryAnalysis,
  },
  {
    path: "/EDA/data-inventory-analysis/Compositon-chart",
    name: "CompositionChart",
    beforeEnter: auth,
    component: CompositionChart,
  },
  {
    path: "/PVT-Properties",
    name: "CorrelationAnalysis",
    beforeEnter: auth,
    component: CorrelationAnalysis,
  },
  {
    path: "/EDA/correlation-analysis/PVT-Properties/correlation-coefficient",
    name: "CorrelationCoefficient",
    beforeEnter: auth,
    component: CorrelationCoefficient,
  },
  {
    path: "/Fluidsdata-insights",
    name: "FluidsdataInsight",
    beforeEnter: auth,
    component: FluidsdataInsight,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound/NotFound.vue"),
  },

  {
    path: "/Visualization-&-Dashboards",
    name: "Main",
    beforeEnter: auth,
    component: Main,
  },

  {
    path: "/Visualization-&-Dashboards/:name",
    name: "Board",
    beforeEnter: auth,
    component: Board,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to save the last visited route
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'NotFound') {
    localStorage.setItem('lastView', to.fullPath);
  }
  if(to.name === "Board") {
    localStorage.setItem("lastVisitedDashboard", to.fullPath)
  }
  next();
});

export default router;