<template>
    <div class="composition-page">
      <chartLoaders v-if="showLoaders == true"></chartLoaders>
      <screen-loader v-if="showLoaders == true"></screen-loader>
      <v-card-text class="chart-table-wrap pa-0" v-else-if="serverItems.length > 0 && showLoaders == false">
        <!-- <v-select
        v-model="selectedSort"
        label="Sort Step Pressure"
        :items="sortOptions"
        @update:modelValue="onSelectSort"
      >
      </v-select> -->
        <div class="chart-wrap" v-if="hasStepPressure">
          <div v-for="(variable, index) in variables" :key="index" class="chart-column">
            <div class="chart-name-top">
            <h6>{{getHeaderDisplayName(variable)}} vs Pressure</h6>
            </div>
            <div :ref="`chart-${index}`" class="main-chart mr-md-2">
            </div>
  
            <v-dialog v-model="isPreviewOpen[index]" class="multi-chart-preview-div dashboard-chart-preview composition">
              <v-card class="position-relative studio-card">
                <v-card-text>
                  <div class="popup-top">
                    <div class="chart-name-top w-100">
                      <h6>{{getHeaderDisplayName(variable)}}</h6>
                    </div>
                     <!-- <img :src="cross" alt="cross" @click="closeModal(index)" class="chart-preview-close-btn"> -->
                  </div>
                  <div :ref="`modal-chart-${index}`" class="modal-chart-container"></div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <div v-else-if="!hasStepPressure" class="text-center py-3">
          <h4>Charts couldn't be plotted as step pressure is not available in this test.</h4>
        </div>
        <div class="table-wrap">
          <div class="table-title-div" v-if="serverItems.length > 0">
          <p class="table-title">Last Stage Liquid Compositions</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <v-img class="pointer" :src="documentDownload" alt="csv download"></v-img>
            <div>
              Download CSV
            </div>
          </div>
        </div>
          <v-data-table :headers="headers" :items="serverItems" :total-items="totalItems" :items-per-page="itemsPerPage"
            :loading="loading" :search="search" :item-value="name" virtual-items fixed-header max-height="calc(100vh - 380px)">
            <template v-slot:headers>
              <tr class="main-header">
                <th v-for="(header, index) in headers" :key="index" class="sticky-header">
                  {{ header.name }}
                  <span @click="handleHeaderClick(header.key)">
                  <span v-if="header.key === 'stepPressure'">
                    <v-icon v-if="sortOrder === 'asc'" @click.stop="sortStepPressure('desc')" small
                      >mdi-arrow-down</v-icon
                    >
                    <v-icon v-else @click.stop="sortStepPressure('asc')" small
                      >mdi-arrow-up</v-icon
                    >
                  </span>
                </span>
                </th>
              </tr>
              <tr class="sub-header">
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.unit }}
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td v-for="(header, index) in headers" :key="index">
                  {{ displayValue(header.key, item[header.key]) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <div v-else-if="(!serverItems || serverItems.length === 0) && showLoaders == false" class="tabs-no-test-div">
        <h4>No data available.</h4>
      </div>
    </div>
  </template>
  
  <script>
  import Plotly from "plotly.js-dist-min";
  import ChartLoaders from "../Loaders/ChartLoaders.vue";
  import axios from "axios";
  // import { mapActions } from "vuex";
  import ScreenLoader from "../Loaders/ScreenLoader.vue";
  import { getDisplayName } from "../../../utils/cache";

  import downloadIcon from '../../../assets/Svg/Chart/csv_chart.svg';
  import Expand from "../../../assets/Svg/Chart/expand-btn.svg";
  
  export default {
    name: "CvdTestProperties",
    components: {
      ChartLoaders,
      ScreenLoader,
    },
    data: () => ({
      cross: require("../../../assets/Svg/Chart/chart-cross.svg"),
      showLoaders: true,
      itemsPerPage: 0,
      headers: [],
      serverItems: [],
      loading: true,
      totalItems: 0,
      tenantId: localStorage.getItem("tenantId"),
      variables: ["1", "2"],
      isPreviewOpen: Array().fill(false),
      chartData: Array().fill(null),
      cachedData: {},
      testID: "",
      testName: "",
      sampleID: "",
      hasStepPressure: false,
      saturationPressure: "",
      saturationPressure_UOM: "",
      testTemperature: "",
      testTemperature_UOM: "",
      documentDownload: require("../../../assets/Images/csv.svg"),
      toggleFraction: true,
    //   selectedSort: "High to Low",
    // sortOptions: ["High to Low", "Low to High"],
    sortOrder: 'desc'
    }),
    methods: {
    //   onSelectSort() {
    //   const sortOrder = this.selectedSort === "High to Low" ? 0 : 1; // Set sort order based on selection

    //   this.loadItems({ sortOrder })
    //     .then((ccetest) => {
    //       this.variables.forEach((variable, index) => {
    //         this.plotChartForVariable(variable, index, ccetest);
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error loading data:", error);
    //     });
    // },
    sortStepPressure(order) {
      this.sortOrder = order;
      if (order === 'asc') {
        this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
      } else {
        this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
      }
    },
      toggleFractionFunction() {
      this.serverItems = [];
      if (this.loading == true && this.serverItems.length == 0) {
        this.showLoaders = true;
      }
      this.chartPlotted();
    },
      downloadTableCSV() {
        // Check if table has items
        if (this.serverItems && this.serverItems.length > 0) {
          const fileName = `constant_volume_depletion_test_properties.csv`;
  
          // Prepare CSV header dynamically from table headers
          const csvHeader = this.headers.map((header) => header.name);
          const csvUnits = this.headers.map((header) => header.unit);
  
          // Prepare CSV rows from table data
          const csvRows = this.serverItems.map((item) =>
            this.headers.map((header) => item[header.key])
          );
  
          // Concatenate header and rows
          const csvContent = [
            csvHeader.join(","),
            csvUnits.join(","),
            ...csvRows.map((row) => row.join(",")),
          ].join("\n");
  
          // Create Blob with CSV content
          const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
          });
  
          // Create link element to trigger download
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName); // Set filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error(
              "Your browser does not support downloading files programmatically. Please try a different browser."
            );
          }
        } else {
          console.error("No data available in the table to download.");
        }
      },
      async loadItems() {
  var sampleId = localStorage.getItem("sampleId");
  var cvdId = localStorage.getItem("testId");
  this.loading = true;
  this.showLoaders = true;

  try {
    let response = await axios.get(
      process.env.VUE_APP_API_URL +
      `public/api/v1/samples/${sampleId}/cvdtests/${cvdId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "X-TenantID": this.tenantId,
        },
      }
    );
    if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
    const cvdtest = response.data.data.cvdtest;

    if (cvdtest && cvdtest.cvdResidComposition) {
      this.serverItems = cvdtest.cvdResidComposition;
      this.totalItems = cvdtest.length;
      this.testID = cvdtest.ID;
      this.testName = cvdtest.name;
      this.sampleID = cvdtest.sampleID;
      this.saturationPressure = cvdtest.saturationPressure;
      this.saturationPressure_UOM = cvdtest.saturationPressure_UOM;
      this.testTemperature = cvdtest.testTemperature;
      this.testTemperature_UOM = cvdtest.testTemperature_UOM;
      this.sendDataToParent();

      const sortByArray = this.toggleFraction ? 'sortByMass' : 'sortByMole';

      // Ensure cvdResidComposition and sortByArray exist before mapping
      if (cvdtest.cvdResidComposition.length > 0 && cvdtest.cvdResidComposition[0][sortByArray]) {
        const sortByKeys = cvdtest.cvdResidComposition[0][sortByArray].flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));

        this.hasStepPressure = this.serverItems.some(item => item.hasOwnProperty('stepPressure'));

        const availableKeys = new Set(
          sortByKeys.filter((key) =>
            this.serverItems.some((item) => key in item)
          )
        );

        const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

        this.variables = Array.from(allKeys).filter(
          (key) =>
            (key !== "stepPressure" &&
              key !== "stepNumber" &&
              this.toggleFraction == true &&
              key.includes("MassFraction")) ||
            (this.toggleFraction == false && key.includes("MoleFraction"))
        );

        const allUniqueKeys = Array.from(allKeys);

        const displayNames = [];
        const digitsArray = [];

        await Promise.all(allUniqueKeys.map(async (key) => {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          const { displayName, digits } = await getDisplayName('CVDResidComposition', capitalizedKey);
          displayNames.push(displayName);
          digitsArray.push(digits);
        }));

        this.headers = Array.from(allKeys).map((key, index) => {
          const uom = this.serverItems.find((item) => item[key + "_UOM"]);
          const name = displayNames[index];
          let unit = uom ? uom[key + "_UOM"] : '-';
          if (!unit || unit === "data-not-available") {
            unit = "-";
          }
          const digits = digitsArray[index] || 0;
          return {
            key: key,
            name: name,
            unit: unit,
            digits: digits,
          };
        });
      } else {
        // Handle case where sortByArray is not found
        console.error(`Sort by array '${sortByArray}' is not found in the response.`);
      }
    } else {
      // Handle case where cvdResidComposition is not found
      console.error("cvdResidComposition is not found in the response.");
    }

    return cvdtest;
  }
  else if (response && response.data && response.data.message) {
    console.error(response.data.message);
          }
  else {
      console.error("Error in fetching data");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    this.loading = false;
    this.showLoaders = false;
  }
},

      downloadChartCSV(index) {
        const chartData = this.chartData[index];
  
        // Check if chart data exists
        if (chartData) {
          const fileName = `${chartData.layout.yaxis.title.text} vs ${chartData.layout.xaxis.title.text} data.csv`;
  
          // Prepare CSV header dynamically
          const csvHeader = [chartData.layout.xaxis.title.text, chartData.layout.yaxis.title.text];
  
          // Prepare CSV rows
          const csvRows = chartData.trace.x.map((xValue, i) => [xValue, chartData.trace.y[i]]);
  
          // Concatenate header and rows
          const csvContent = [csvHeader.join(','), ...csvRows.map(row => row.join(','))].join('\n');
  
          // Create Blob with CSV content
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
          // Create link element to trigger download
          const link = document.createElement('a');
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName); // Set filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error('Your browser does not support downloading files programmatically. Please try a different browser.');
          }
        }
      },
  
      async plotChartForVariable(variable, chartIndex, cvdtest) {
        if (!cvdtest) {
          console.error("No data provided for plotting chart.");
          return;
        }

        // Find the digits value for the current variable
const header = this.headers.find(header => header.key === variable);
const digits = header ? header.digits : 0;

// Find the digits value for the stepPressure variable
const stepPressureHeader = this.headers.find(header => header.key === 'stepPressure');
const stepPressureDigits = stepPressureHeader ? stepPressureHeader.digits : 0;

// Filter and map the data points together
const filteredData = cvdtest.cvdTestSteps
.filter(step => step.stepPressure !== undefined && step.stepPressure !== null && step[variable] !== undefined && step[variable] !== null)
.map(step => ({
x: step.stepPressure.toFixed(stepPressureDigits),
y: step[variable].toFixed(digits)
}));
  
        const trace = {
          x: filteredData.map(data => data.x),
          y: filteredData.map(data => data.y),
          type: "scatter",
          mode: "lines+markers",
          name: variable,
        };
  
        const layout = {
          xaxis: { title: "Pressure (psia)" },
          yaxis: {
            title: this.getHeaderDisplayName(variable)
          },
          margin: {
          l: 50,
        },
        };
        const config = {
          responsive: true,
          displaylogo: false,
          displayModeBar: true,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d', "resetscale","zoom"],
          modeBarButtonsToAdd: [
            {
              name: "Expand",
              icon: {
                svg: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <image href="${Expand}" width="24" height="24"/>
                  </svg>
                `,
              },
              click: this.openModal.bind(this, chartIndex),
            },
            {
              name: 'Download CSV',
              icon: {
                svg: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <image href="${downloadIcon}" width="24" height="24"/>
                  </svg>
                `,
              },
              click: this.handleDownloadCSV.bind(this, chartIndex)
            },
          ]
        };
  
        this.chartData[chartIndex] = {
          trace: trace,
          layout: layout,
          config: config,
        };
  
        const chartRef = this.$refs["chart-" + chartIndex];
        if (chartRef && chartRef.length > 0) {
          const chartElement = chartRef[0];
          Plotly.newPlot(chartElement, [trace], layout, config);
        } else {
          return
        }
      },
  
      getHeaderDisplayName(variable) {
  const header = this.headers.find(header => header.key === variable);
  if (header) {
    return header.unit !== '-' ? `${header.name} (${header.unit})` : header.name;
  }
  return variable;
},
  
displayValue(key, value) {
  const header = this.headers.find(header => header.key === key);
  const digits = header ? header.digits : 0; // Get digits from header

  if (value !== null && value !== undefined) {
    // Check if value is a string to avoid formatting
    if (typeof value === 'string') {
      return value;
    } else {
      // Format the value with the specified number of decimal places
      const numValue = Number(value);
      if (!isNaN(numValue)) {
        return digits > 0 ? numValue.toFixed(digits) : value;
      }
    }
  } else {
    return "-";
  }
},
  
      handleDownloadCSV(chartIndex) {
        this.downloadChartCSV(chartIndex);
      },
  
      openModal(index) {
        this.isPreviewOpen[index] = true;
        this.resizeChart(index);
        this.$nextTick(() => {
          this.plotModalChart(index);
        });
      },
      resizeChart(index) {
        if (this.$refs[`modal-chart-${index}`]) {
          Plotly.Plots.resize(this.$refs[`modal-chart-${index}`]);
        }
      },
      closeModal(index) {
        this.isPreviewOpen[index] = false;
      },
      plotModalChart(index) {
        const chartData = this.chartData[index];
        if (chartData) {
          const modalChartRef = this.$refs[`modal-chart-${index}`][0];
          Plotly.newPlot(
            modalChartRef,
            [chartData.trace],
            chartData.layout,
            chartData.config
          );
        }
      },
      sendDataToParent() {
        this.$emit('data-updated', {
          testID: this.testID,
          testName: this.testName,
          sampleID: this.sampleID,
          saturationPressure: this.saturationPressure,
          saturationPressure_UOM: this.saturationPressure_UOM,
          testTemperature: this.testTemperature,
          testTemperature_UOM: this.testTemperature_UOM,
        });
      },
      chartPlotted() {
      this.loadItems().then((ccetest) => {
        // Determine which variables to plot based on toggle state
        const variablesToPlot = [];
        if (this.toggleFraction == true) {
          variablesToPlot.push("FluidComponentReference", "liquid_MassFraction");
        }
        if (this.toggleFraction == false) {
          variablesToPlot.push("FluidComponentReference", "liquid_MoleFraction");
        }

        if (
          JSON.stringify(variablesToPlot) !==
          JSON.stringify(this.variablesToPlot)
        ) {
          this.variablesToPlot = variablesToPlot; // Update the tracked variables to plot
          this.plotCharts(variablesToPlot, ccetest); // Plot charts only for the variables enabled by the toggle switches
        }

        // Plot charts only for the variables enabled by the toggle switches
        variablesToPlot.forEach((variable, index) => {
          this.plotChartForVariable(variable, index, ccetest);
        });
      });
    },
    plotCharts(variablesToPlot, ccetest) {
      variablesToPlot.forEach((variable, index) => {
        this.plotChartForVariable(variable, index, ccetest);
      });
    },
    },
    watch: {
      $route(to, from) {
        // Call the loadItems method when route parameters change
        this.loadItems()
          .then((cvdtest) => {
            this.variables.forEach((variable, index) => {
              this.plotChartForVariable(variable, index, cvdtest);
            });
          })
          .catch((error) => {
            console.error("Error loading data:", error);
            // You may want to handle errors here
          });
      },
    },
    mounted() {
        this.chartPlotted();
    },
  };
  </script>