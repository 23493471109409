// import axios from 'axios';
import api from "../axiosInterceptor"

const CACHE_NAME = 'metaDataCache';

export default {
  namespaced: true,
  state: {
    loginError: null,
    logoutError: null,
  },
  getters: {
    getLoginError: state => state.loginError,
    getLogoutError: state => state.logoutError,
  },
  mutations: {
    setLoginError(state, error) {
      state.loginError = error;
    },
    setLogoutError(state, error) {
      state.logoutError = error;
    },
    clearLoginError(state) {
      state.loginError = null;
    },
    clearLogoutError(state) {
      state.logoutError = null;
    },
  },
  actions: {

    sendLoginRequest({ commit}, data) {
      commit("clearLoginError");
      commit("setErrors", {}, { root: true });
      return api
        .post("login", data)
        .then(response => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            localStorage.setItem("authToken", response.data.data.token);
            localStorage.removeItem("lastFilterId")
          }
          else if (response && response.data && response.data.message) {
            commit("setLoginError", response.data.message);
          }
          else {
            commit("setLoginError", "Error in login");
          }
          return response;
        })
        .catch(error => {
          if (error.response) {
            const errorMessage = error.response.data.error;
            commit("setLoginError", errorMessage);
          }
          else {
            const loginTimeoutMessage = error;
            commit("setLoginError", loginTimeoutMessage)
          }
        });
    },
     sendLogoutRequest({ commit }) {
      commit("clearLogoutError");
      return api.post("logout", {})
        .then(response => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("lastVisitedDashboard");
            localStorage.removeItem("savedChartData")
            localStorage.removeItem("lastFilterId")
            localStorage.removeItem('itemsPerPage');
            localStorage.removeItem('defaultFilter');
            caches.delete(CACHE_NAME);
          }
          else if (response && response.data && response.data.message) {
            commit("setLogoutError", response.data.message);
          }
          else {
            commit("setLogoutError", "Error in logout");
          }
          return response;
        })
        .catch((error) => {
          const logoutTimeoutMessage = error;
          commit("setLogoutError", logoutTimeoutMessage)
        });
    },
  },
};