<template>
    <!-- <div class="data-management" :style="rightContentStyle"> -->
    <div class="exploration-wrapper pt-0" :style="rightContentStyle">
        <!-- <RecentActivity /> -->
        <PreDefinedFilters :drawerValue="drawerValue" @showFilterDiv="showFilterDiv"
            @getFilterDetailTopage="getFilterDetailTopage" ref="preFilter" />
        <TableData ref="tableData" @resetFiltersEmitted="resetFiltersEmitted" :filterData="filterData"
            :isLoading="isLoading" :filterDetails="filterDetails" :showFilter="showFilter"
            @update:pagination="handlePaginationUpdate" @getFilterFromTable="getFilterFromTable" />
             <div class="filter-wrapper">
        <Filters v-if="isDataExploration" @drawerStateChanged="handleRightDrawerStateChange"
            @filterApplied="applyFilter" @resetFiltersEmittedUpdated="resetFiltersEmittedUpdated" @drawerValue="holdDrawerValue"
            @updateFilter="callSummaryAgain" ref="filters" />
            </div>
    </div>
</template>

<script>
import PreDefinedFilters from "@/components/DataExploration/PreDefinedFilters.vue";
// import RecentActivity from "@/components/DataExploration/RecentActivity.vue";
import TableData from "@/components/DataExploration/TableData.vue";
import Filters from "@/components/layout/Common/Filters.vue";
import screenWidthMixin from "../assets/JS/screenWidthMixin";
import api from "@/axiosInterceptor";

export default {
    components: {
        // RecentActivity,
        PreDefinedFilters,
        TableData,
        Filters,
    },
    name: "DataExploration",
    mixins: [screenWidthMixin],
    data: () => ({
        showFilter: true,
        filterData: null,
        currentFilterId: null,
        isLoading: false,
        currentPage: 1,
        currentItemsPerPage: 20,
        filterDetails: null, // Added to store filter details
        isFilterChanged: false, // Added to track if filter has been changed
        drawerValue: false,
        sortColumn: "",
        sortDirection: ""
    }),
    computed: {
        isDataExploration() {
            return this.$route.path === '/data-management';
        },
    },
    methods: {
        holdDrawerValue(val) {
            this.drawerValue = val;
        },
        async getFilterDetailTopage(filter) {
            this.$refs.filters.resetFilter();
            this.currentFilterId = filter.ID;
            localStorage.setItem('lastFilterId', filter.ID);
            this.currentPage = 1; // Reset to first page when filter changes
            this.isFilterChanged = true; // Mark filter as changed
            await this.fetchFilterDetails(); // Changed to fetch filter details first
        },

        async showFilterDiv(val) {
            this.showFilter = val;
        },

        async fetchFilterDetails() {
            this.isLoading = true;
            try {
                const response = await api.get(`public/api/v1/filters/${this.currentFilterId}`);
                if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
                    this.filterDetails = response.data.data.filter; // Store filter details
                    this.$refs.filters.onSelectFilter(this.filterDetails.name);
                    this.fetchFilterData(); // Call fetchFilterData after getting filter details
                }
                else if (response && response.data && response.data.message) {
                    console.error(response.data.message);
                }
                else {
                    console.error("Error fetching filter details.");
                }
            } catch (error) {
                console.error("Error fetching filter details:", error);
            } finally {
                this.isLoading = false;
            }
        },
        async getFilterFromTable(filterData) {
            if (filterData.withFilter) {
                this.filterDetails = filterData;
            } else {
                this.filterDetails = {
                    asset: filterData.assets,
                    field: filterData.fields,
                    well: filterData.wells,
                    reservoir: filterData.reservoirs,
                    sampleKind: filterData.sampleKind,
                    selectedTests: filterData.tests,
                };
            }
            this.sortColumn = filterData.sortColumn;
            this.sortDirection = filterData.sortDirection;
            this.$refs.filters.populateFieldsFromFilter(this.filterDetails);
            await this.fetchFilterData();
        },
        async fetchFilterData() {
            this.isLoading = true;
            try {
                const params = {
                    pageSize: this.currentItemsPerPage,
                    pageNumber: this.currentPage,
                    viewSummary: true
                };
                if (this.filterDetails) {
                    params.field = this.filterDetails.field?.length ? `["${this.filterDetails.field.join('","')}"]` : '[]';
                    params.well = this.filterDetails.well?.length ? `["${this.filterDetails.well.join('","')}"]` : '[]';
                    params.asset = this.filterDetails.asset?.length ? `["${this.filterDetails.asset.join('","')}"]` : '[]';
                    params.reservoir = this.filterDetails.reservoir?.length ? `["${this.filterDetails.reservoir.join('","')}"]` : '[]';
                    params.sampleKind = this.filterDetails.sampleKind?.length ? `["${this.filterDetails.sampleKind.join('","')}"]` : '[]';
                    params.selectedTests = this.filterDetails.selectedTests?.length
                        ? `["${this.filterDetails.selectedTests.map(test => test.toLowerCase().replace(/\s+/g, '')).join('","')}"]`
                        : '[]';
                    if (this.sortColumn) {
                        params.sortColumn = `"${this.sortColumn.replace(/s$/, "").toLowerCase()}"`;
                    }

                    // Add sortDirection only if it has a value
                    if (this.sortDirection) {
                        params.sortDirection = `"${this.sortDirection}"`;
                    }
                }
                const response = await api.get(`public/api/v1/samples`, { params });
                if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
                    this.filterData = response.data.data; // Update filterData with new data
                    if (this.filterData.length === 0) { // Check if no data is available
                        this.filterData = null; // Set filterData to null to indicate no data found
                    }
                }
                else if (response && response.data && response.data.message) {
                    console.error(response.data.message);
                }
                else {
                    console.error("Error fetching filter details.");
                }
            } catch (error) {
                console.error("Error fetching filter data:", error);
            } finally {
                this.isLoading = false;
            }
        },
        async handlePaginationUpdate({ page, itemsPerPage }) {
            this.currentPage = page;
            this.currentItemsPerPage = itemsPerPage;
            if (this.isFilterChanged) { // Check if filter has been changed
                this.filterData = null;
                // Fetch filter details again on pagination update if filter has been changed
                if (!this.filterDetails || Object.keys(this.filterDetails).every(key => this.filterDetails[key].length === 0)) {
                    await this.fetchFilterData(); // Call fetchFilterData without filter if all filter fields are empty
                } else {
                    if (this.currentFilterId && !this.filterDetails.ID) {
                        await this.fetchFilterDetails();
                    } else {
                        this.fetchFilterData();
                    }
                }
            } else {
                await this.fetchFilterData(); // Call fetchFilterData directly if filter has not been changed
            }
        },

        async resetFiltersEmitted() {
            this.filterData = null;
            this.currentFilterId = null;
            localStorage.removeItem('lastFilterId');
            this.currentPage = 1; // Reset to first page after filter reset
            this.currentItemsPerPage = localStorage.getItem('itemsPerPage') || 20; // Reset items per page after filter reset
            this.isFilterChanged = false;
            this.filterDetails = null // Reset filter change flag after filter reset
            this.$refs.filters.resetFilter(); // Call reset function in Filters component
            this.$refs.preFilter.resetCardSelection();
            if (this.$refs.tableData && typeof this.$refs.tableData.resetTableFilter === 'function') {
                this.$refs.tableData.resetTableFilter(); // Example function
            }
            await this.fetchFilterData(); // Call fetchFilterData to reset the table data
        },
        async resetFiltersEmittedUpdated() {
            this.filterData = null;
            this.filterDetails = this.filterData
            this.currentFilterId = null;
            this.currentPage = 1; // Reset to first page after filter reset
            this.currentItemsPerPage = localStorage.getItem('itemsPerPage') || 20; // Reset items per page after filter reset
            if (this.$refs.tableData && typeof this.$refs.tableData.resetTableFilter === 'function') {
                this.$refs.tableData.resetTableFilter(); // Example function
            }
            this.$refs.preFilter.resetCardSelection();
            await this.fetchFilterData(); // Call fetchFilterData to reset the table data
        },

        async applyFilter(filterDetails) {
            this.filterDetails = filterDetails.filterData; // Update filterDetails with the new filter
            this.isFilterChanged = true; // Mark filter as changed
            this.currentPage = 1; // Reset to first page when filter changes
            this.$refs.preFilter.CardSelection(filterDetails.filterData);
            await this.fetchFilterData(); // Fetch new data based on the applied filter
        },
        callSummaryAgain() {
            // this.resetFiltersEmitted()
            this.$refs.preFilter.getFilterSummaries();
        },
    },
    mounted() {
        const lastFilterId = localStorage.getItem('lastFilterId');
        if (lastFilterId) {
            this.currentFilterId = lastFilterId;
            this.isFilterChanged = true; // Mark filter as changed on component creation if lastFilterId exists
            this.fetchFilterDetails(); // Fetch filter details on component creation if lastFilterId exists
        } else {
            this.fetchFilterData(); // Call fetchFilterData on component creation to fetch data without filters if lastFilterId does not exist
        }
    },
    async beforeDestroy() {
        this.isLoading = true;
        try {
            const params = {
                pageSize: this.currentItemsPerPage,
                pageNumber: this.currentPage,
                viewSummary: true
            };
            const response = await api.get(`public/api/v1/samples`, { params });
            if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
                this.filterData = response.data.data;
            }
            else if (response && response.data && response.data.message) {
                    console.error(response.data.message);
          }
          else {
      console.error("Error fetching sample data.");
    }
        } catch (error) {
            console.error("Error fetching sample data before destroy:", error);
        } finally {
            this.isLoading = false;
        }
    }
};
</script>