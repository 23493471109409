<template>
  <div class="left-menu-wrapper">
    <v-icon
      class="toggle-icon"
      :icon="
        isSidebarClosed ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'
      "
      :style="toggleIconPosition"
      @click="toggleDrawer"
    ></v-icon>

    <div :class="['sidebar', { close: isSidebarClosed }]">
      <div class="logo-div" v-if="!isSidebarClosed">
        <div class="img-wrap">
          <v-img aspect-ratio="16/9" :src="logo.logo" alt="logo"></v-img>
        </div>
      </div>

      <div class="logo-div2" v-if="isSidebarClosed">
        <div class="img-wrap2">
          <v-img :src="logo.logoIcon" alt="logo"></v-img>
        </div>
      </div>

      <ul class="nav-list">
        <!-- Loop through navigationItems -->
        <li
          v-for="(item, index) in navigationItems"
          :key="index"
          @click="toggleSubmenu(index)"
          :class="{ show: item.isVisible }"
        >
          <div
            v-if="item.children"
            class="icon-link"
           :class="{ 'active-item': item.title === activeMenu }"
          >
            <router-link v-if="item.route" :to="item.route" class="link">
              <img
                :src="
                  item.title === activeMenu ? item.activeImage : item.image
                "
              />
              <span class="link-name">{{ item.title }}</span>
            </router-link>
            <div v-else class="link">
              <img
                :src="
                  $route.path === item.route ? item.activeImage : item.image
                "
              />
              <span class="link-name">{{ item.title }}</span>
            </div>
            <div class="arrow-img arrow">
              <v-img
                src="../../../assets/Images/LeftMenu/rightarrowblack.png"
                alt="arrow down"
              ></v-img>
            </div>
          </div>
          <ul
            v-if="item.children"
            :class="{ show: item.isVisible }"
            class="sub-menu"
          >
            <li
              v-for="(childItem, childIndex) in item.children"
              :class="{ 'active-item': $route.path === childItem.route }"
              :key="childIndex"
            >
              <router-link
                v-if="childItem.route"
                :to="childItem.route"
                class="sub-link"
              >
                <!-- <div class="img-div"> -->
                <!-- <img :src="childItem.image" /> -->
                <img
                  :src="
                    $route.path === childItem.route
                      ? childItem.activeImage
                      : childItem.image
                  "
                />
                <!-- </div> -->
                <span
                  :class="{ 'active-text': item.title === activeMenu}"
                  >{{ childItem.title }}</span
                >
              </router-link>
              <span v-else class="sub-link">
                <!-- <div class="img-div"> -->
                <img :src="childItem.image" />
                <!-- </div> -->
                <span
                  :class="{ 'active-text': $route.path === childItem.route }"
                  >{{ childItem.title }}</span
                >
              </span>
            </li>
          </ul>
          <div
            class="icon-link"
            v-else
            :class="{ 'active-item': item.title === activeMenu }"
          >
            <router-link v-if="item.route" :to="item.route" class="link">
              <!-- <div class="img-div"> -->
              <img
                :src="
                  item.title === activeMenu ? item.activeImage : item.image
                "
              />
              <!-- </div> -->
              <span
                class="link-name"
                :class="{ 'active-text': item.title === activeMenu }"
                >{{ item.title }}</span
              >
            </router-link>
            <div v-else class="link" @click="handleClick(item)">
              <!-- <div class="img-div"> -->
              <img :src="item.image" />
              <!-- </div> -->
              <div class="eda-content">
                <span class="link-name">{{ item.title }}</span>
                <div
                  class="add-icon"
                  v-if="item.title === 'exploratory data analysis'"
                >
                  <v-img
                    src="../../../assets/Images/LeftMenu/add.png"
                    alt="plus icon"
                    class="add-image"
                  ></v-img>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="left-footer" v-if="!isSidebarClosed">
        <div class="footer-img">
          <v-img aspect-ratio="16/9" :src="logo.tenantName" alt="logo"></v-img>
        </div>
        <!-- <span>{{
          getSelectedTenant ? getSelectedTenant.name : selectedTenant
        }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  data: () => ({
    isVisible: false,
    isSidebarClosed: false,
    screenWidth: 0,
    drawer: true,
    rail: false,
    miniVariant: false,
    selectedTenant: localStorage.getItem("tenantName"),
    activeMenu: '', 
    logo: {
      logo: require("../../../assets/Images/LeftMenu/logo.png"),
      logoIcon: require("../../../assets/Images/LeftMenu/logoicon.png"),
      tenantName: require("../../../assets/Svg/LeftMenu/logo-full.svg"),
      clientLogo: require("../../../assets/Images/LeftMenu/Client Logo.png"),
    },
    navigationItems: [
      {
        title: "PVT Tests",
        route: "/assets",
        image: require("../../../assets/Svg/LeftMenu/dm_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/dm_white.svg"),
      },
      {
        title: "Data Management",
        route: "/data-management",
        image: require("../../../assets/Svg/LeftMenu/de_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/de_white.svg"),
      },
      // {
      //   title: "data QA-QC",
      //   route: "",
      //   image: require("../../../assets/Svg/LeftMenu/data-QA-QC_black.svg"),
      // },
      {
        title: "Studio",
        route: "/Fluidsdata-studio",
        image: require("../../../assets/Svg/LeftMenu/studio_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/studio_white.svg"),
      },
      {
        title: "Studio-one",
        route: "/Fluidsdata-studio-one",
        image: require("../../../assets/Svg/LeftMenu/studio_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/studio_white.svg"),
      },
      {
        title: "Studio-two",
        route: "/Fluidsdata-studio-two",
        image: require("../../../assets/Svg/LeftMenu/studio_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/studio_white.svg"),
      },
      {
        title: "visualization & dashboards",
        route: "/Visualization-&-Dashboards",
        image: require("../../../assets/Svg/LeftMenu/dashboard_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/dashboard_white.svg"),
      },
      {
        title: "exploratory data analysis",
        image: require("../../../assets/Svg/LeftMenu/eda_black.svg"),
        activeImage: require("../../../assets/Svg/LeftMenu/eda_white.svg"),
        // children: [
        //   {
        //     title: "Data Inventory Analysis",
        //     image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //     activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //     route: "/data-inventory-analysis"
        //   },
        //   {
        //     title: "correlation analysis",
        //     route: "/PVT-Properties",
        //     image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //     activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //   },
        //   {
        //     title: "fluidsdata insights",
        //     route: "/Fluidsdata-insights",
        //     image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //     activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //   },
        // ],
      },
      // {
      //   title: "Property Predictions",
      //   image: require("../../../assets/Svg/LeftMenu/property_prediction_black.svg"),
      //   activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //   isVisible: false,
      //   children: [
      //     {
      //       title: "AI / ML Modeling",
      //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
      //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //       route: "/ai-ml-modelling",
      //     },
      //     {
      //       title: "Advanced QA-QC",
      //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
      //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //     },
      //     {
      //       title: "Dimensionality Reduction",
      //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
      //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //     },
      //     {
      //       title: "Clustering",
      //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
      //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //     },
      //     {
      //       title: "Supervised ML Model",
      //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
      //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //     },
      //     {
      //       title: "Fluidsdata AI Model",
      //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
      //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
      //       route:
      //         "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model",
      //     },
      //   ],
      // },
    ],
  }),
  props: {
    menu: {
      type: Boolean,
    },
  },
  watch: {
    // '$route.path'(newPath) {
    //   // Find the active item based on the new route path
    //   const activeItem = this.navigationItems.find(item => newPath === item.route);
    //   if (activeItem) {
    //     localStorage.setItem("activeMenu", activeItem.title);
    //   }
    // },
    "$route.path"(newPath) {
      let activeItem = null;
      let activeSubItem = null;

      // Check if the new route path matches any main item
      activeItem = this.navigationItems.find((item) => newPath === item.route);

      // If no main item matches, check subitems
      if (!activeItem) {
        this.navigationItems.forEach((item) => {
          if (item.children) {
            activeSubItem = item.children.find(
              (subItem) => newPath === subItem.route
            );
            if (activeSubItem) {
              activeItem = item;
              localStorage.setItem("activeMenu", activeSubItem.title); // Store subitem title
              localStorage.setItem("activeSubMenu", activeItem.title); // Optionally store parent item title
            }
          }
        });
      } else {
        const currentActiveMenu = this.activeMenu;
        // Save both to localStorage
        localStorage.setItem("lastActiveMenu", currentActiveMenu);
        localStorage.setItem("activeMenu", activeItem.title); // Store main item title
      }
      // this.activeMenu = localStorage.getItem('activeMenu');

      const activeMenu = localStorage.getItem("activeMenu") || "PVT Tests";
      const lastActiveMenu = localStorage.getItem("lastActiveMenu");
      const routePath = this.$route.path;
      if (["/assets", "/data-management", "/Fluidsdata-studio", "/Fluidsdata-studio-one", "/Fluidsdata-studio-two", "/Visualization-&-Dashboards"].includes(routePath)) {
        this.activeMenu = activeMenu;
      } else {
        if (lastActiveMenu === "PVT Tests" && activeMenu === "Data Management") {
          this.localStorageMenuTitle = activeMenu;
        } 
        else if (lastActiveMenu === "Data Management" && activeMenu === "PVT Tests") {
          this.localStorageMenuTitle = activeMenu;
        }
        else if (lastActiveMenu === "PVT Tests") {
          this.activeMenu = lastActiveMenu;
        } else if (lastActiveMenu === "Data Management") {
          this.activeMenu = lastActiveMenu;
        }
      }
    },
  },
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    toggleIconPosition() {
      return this.drawer ? "right: -12px;" : "left: 52px;";
    },
  },
  methods: {
    handleClick(item) {
      if (item.title === "exploratory data analysis") {
        const url = "https://edaml.fluidsdata.ai/";
        window.open(url, "_blank");
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
      this.$emit("drawerStateChanged", this.drawer);
      this.isSidebarClosed = !this.isSidebarClosed;
      this.$emit("drawerHold", this.drawer);
    },
    toggleSubmenu(index) {
      // Loop through all items and close all submenus except the one being opened
      this.navigationItems.forEach((item, i) => {
        if (i === index) {
          // Toggle the clicked submenu
          item.isVisible = !item.isVisible;
        }
      });
    },
    checkScreenWidth() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 768) {
        this.drawer = false;
        this.isSidebarClosed = true;
      } else if (this.screenWidth > 768) {
        if (this.menu) {
          this.drawer = true;
          this.isSidebarClosed = false;
        } else {
          this.drawer = false;
          this.isSidebarClosed = true;
        }
      }
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.toggleDrawer();
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>
