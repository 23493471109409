<template>
  <div class="composition-page">
    <chartLoaders v-if="showLoaders == true"></chartLoaders>
    <screen-loader v-if="showLoaders == true"></screen-loader>

    <div v-else-if="timeoutMessage != ''" class="tabs-no-test-div timeout">
      <div class="text-center">
        {{ timeoutMessage }}
        <v-icon
          flat
          @click="loadItems()"
          color="blue-darken-3"
          icon="mdi-reload"
          size="large"
        ></v-icon>
      </div>
    </div>

    <v-card-text
      class="chart-table-wrap px-0 pb-0"
      v-else-if="serverItems.length > 0 && showLoaders == false"
    >
      <div v-if="hasVariablesToPlot" class="chart-wrap">
        <div
          v-for="(variable, index) in variables"
          :key="index"
          class="chart-column"
        >
          <div class="chart-name-top">
            <h6>{{ getHeaderDisplayName(variable) }}</h6>
          </div>
          <div :ref="`chart-${index}`" class="main-chart">
            <!-- <div>
              <v-btn v-if="showExpandButton[index]" @click="openModal(index)" flat size="small"
                class="chart-preview-btn2 text-none">
                
                <img :src="expandIcon" alt="Expand Icon">
              </v-btn>
            </div> -->
          </div>

          <v-dialog
            v-model="isPreviewOpen[index]"
            class="multi-chart-preview-div dashboard-chart-preview composition"
          >
            <v-card class="position-relative studio-card">
              <v-card-text>
                <div class="popup-top">
                  <div class="chart-name-top w-100">
                    <h6>{{ getHeaderDisplayName(variable) }}</h6>
                  </div>
                  <!-- <img :src="cross" alt="cross" @click="closeModal(index)" class="chart-preview-close-btn"> -->
                </div>
                <div
                  :ref="`modal-chart-${index}`"
                  class="modal-chart-container"
                ></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else-if="!fluidComponentReference" class="text-center pt-1 pb-5">
        <h4>
          Charts couldn't be plotted as fluid component reference is not
          available in this test.
        </h4>
      </div>
      <div v-else class="text-center pt-1 pb-5">
        <!-- Add this block -->
        <h4>No variables available for plotting.</h4>
      </div>
      <div class="table-wrap">
        <div class="table-title-div" v-if="serverItems.length > 0">
          <p class="table-title">Composition Analysis</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <v-img
              class="pointer"
              :src="documentDownload"
              alt="csv download"
            ></v-img>
            <div>Download CSV</div>
          </div>
        </div>
        <v-data-table
          v-if="showHeaders() && serverItems.length > 0"
          :headers="visibleHeaders"
          :items="visibleItems"
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          :loading="loading"
          :search="search"
          :item-value="name"
          virtual-items
          fixed-header
          max-height="calc(100vh - 380px)"
        >
          <template v-slot:headers>
            <tr class="main-header">
              <th
                v-for="(header, index) in visibleHeaders"
                :key="index"
                class="sticky-header"
              >
                {{ header.name }}
              </th>
            </tr>
            <tr class="sub-header">
              <th v-for="(header, index) in visibleHeaders" :key="index">
                {{ header.unit }}
              </th>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, index) in items" :key="index">
              <td v-for="(header, index) in visibleHeaders" :key="index">
                {{ displayValue(header.key, item[header.key]) }}
              </td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr>
              <td v-for="(header, index) in visibleHeaders" :key="index">
                <!-- Display the sum of MassFraction when toggleFraction is true -->
                <span
                  v-if="header.key.includes('MassFraction') && toggleFraction"
                >
                  {{
                    sums[header.key] ? truncateNumber(sums[header.key], 3) : "-"
                  }}
                </span>

                <!-- Display the sum of MoleFraction when toggleFraction is false -->
                <span
                  v-if="header.key.includes('MoleFraction') && !toggleFraction"
                >
                  {{
                    sums[header.key] ? truncateNumber(sums[header.key], 3) : "-"
                  }}
                </span>
                <span v-if="header.key.includes('fluidComponentReference')"
                  >Total</span
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <div
      v-else-if="
        (!serverItems || serverItems.length === 0) && showLoaders == false
      "
      class="tabs-no-test-div"
    >
      <h4>No data available.</h4>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ChartLoaders from "../../Loaders/ChartLoaders.vue";
import ScreenLoader from "../../Loaders/ScreenLoader.vue";
import { getDisplayName } from "../../../../utils/cache";
import api from "../../../../axiosInterceptor";

import downloadIcon from "../../../../assets/Svg/Chart/csv_chart.svg";
import Expand from "../../../../assets/Svg/Chart/expand-btn.svg";

export default {
  name: "CompositionAnalysisTab",
  components: {
    ChartLoaders,
    ScreenLoader,
  },
  data: () => ({
    documentDownload: require("../../../../assets/Images/csv.svg"),
    cross: require("../../../../assets/Svg/Chart/chart-cross.svg"),
    itemsPerPage: 0,
    headers: [],
    serverItems: [],
    loading: true,
    totalItems: 0,
    tenantId: localStorage.getItem("tenantId"),
    variables: ["1", "2"],
    isPreviewOpen: Array().fill(false),
    chartData: Array().fill(null),
    cachedData: {},
    testID: "",
    testName: "",
    sampleID: "",
    fluidSampleID: "",
    fluidComponentReference: false,
    saturationPressure: "",
    saturationPressure_UOM: "",
    testTemperature: "",
    testTemperature_UOM: "",
    toggleFraction: true,
    showLoaders: true,
    hasVariablesToPlot: true,
    showExpandButton: Array().fill(false),
    timeoutMessage: "",
    sums: {},
  }),
  props: {
    yAxisScale: { type: String, default: "linear" },
  },
  computed: {
    visibleHeaders() {
      const headers = [];
      this.headers.forEach((header) => {
        if (
          header.key === "fluidComponentReference" ||
          (this.toggleFraction == true &&
            header.key.includes("MassFraction")) ||
          (this.toggleFraction == false && header.key.includes("MoleFraction"))
        ) {
          headers.push(header);
        }
      });
      return headers;
    },

    // Computed property to filter items based on toggle state
    visibleItems() {
      return this.serverItems.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (
            key === "fluidComponentReference" ||
            (this.toggleFraction == true && key.includes("MassFraction")) ||
            (this.toggleFraction == false && key.includes("MoleFraction"))
          ) {
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
    },
  },
  watch: {
    serverItems: {
      handler(newItems) {
        this.calculateSums(); // Call calculateSums when serverItems change
      },
      deep: true,
    },
    $route(to, from) {
      this.chartPlotted();
    },
    toggleFraction(newVal, oldVal) {
      this.chartPlotted();
      this.calculateSums();
    },
    yAxisScale() {
      this.chartPlotted();
    },
  },
  methods: {
    truncateNumber(value, decimals) {
      const factor = Math.pow(10, decimals);
      const truncatedValue = Math.floor(value * factor) / factor;
      return truncatedValue.toFixed(decimals); // Ensures 3 decimal places are always shown
    },

    calculateSums() {
      const sums = {};

      this.visibleHeaders.forEach((header) => {
        if (header.key.includes("MassFraction") && this.toggleFraction) {
          sums[header.key] = this.serverItems.reduce((sum, item) => {
            return sum + (parseFloat(item[header.key]) || 0);
          }, 0);
        } else if (
          header.key.includes("MoleFraction") &&
          !this.toggleFraction
        ) {
          sums[header.key] = this.serverItems.reduce((sum, item) => {
            return sum + (parseFloat(item[header.key]) || 0);
          }, 0);
        }
      });

      this.sums = sums;
    },

    async toggleFractionFunction() {
      this.serverItems = [];
      if (this.loading == true && this.serverItems.length == 0) {
        this.showLoaders = true;
      }
      await this.chartPlotted();
      this.$emit("toggle-fraction", this.toggleFraction);
    },

    async plotCharts(variablesToPlot, ccetest) {
      variablesToPlot.forEach((variable, index) => {
        this.plotChartForVariable(variable, index, ccetest);
      });
    },

    showHeaders() {
      return this.toggleFraction == true || this.toggleFraction == false;
    },

    async loadItems() {
      var sampleId = localStorage.getItem("sampleId");
      var compositionId = localStorage.getItem("testId");
      this.loading = true;
      this.showLoaders = true;
      this.timeoutMessage = "";

      try {
        let response = await api.get(
          `public/api/v1/samples/${sampleId}/composition/${compositionId}`
        );
        if (
          response &&
          response.data &&
          (response.data.statusCode === 200 || response.data.statusCode === 201)
        ) {
          this.serverItems =
            response.data.data.composition &&
            response.data.data.composition.composition
              ? response.data.data.composition.composition
              : [];
          this.totalItems = response.data.data.composition.length;
          this.testID = response.data.data.composition.ID;
          this.testName = response.data.data.composition.name;
          this.sampleID = response.data.data.composition.sampleID;
          this.fluidSampleID = response.data.data.composition.fluidSampleID;
          this.saturationPressure =
            response.data.data.composition.saturationPressure;
          this.saturationPressure_UOM =
            response.data.data.composition.saturationPressure_UOM;
          this.testTemperature = response.data.data.composition.testTemperature;
          this.testTemperature_UOM =
            response.data.data.composition.testTemperature_UOM;
          this.sendDataToParent();

          const sortByKeys = response.data.data.composition.sortBy.map(
            (key) => key.charAt(0).toLowerCase() + key.slice(1)
          );
          // Check if serverItems contains fluidComponentReference
          this.fluidComponentReference = this.serverItems.some((item) =>
            item.hasOwnProperty("fluidComponentReference")
          );

          const availableKeys = new Set(
            sortByKeys.filter((key) =>
              this.serverItems.some((item) => key in item)
            )
          );

          const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

          // Update variables array with extracted keys, excluding fluidComponentReference
          // this.variables = Array.from(allKeys).filter(key => key !== 'fluidComponentReference');

          this.variables = Array.from(allKeys).filter(
            (key) =>
              (key !== "fluidComponentReference" &&
                key !== "stepNumber" &&
                this.toggleFraction == true &&
                key.includes("MassFraction")) ||
              (this.toggleFraction == false && key.includes("MoleFraction"))
          );

          // Filter out "H2" from serverItems
          this.serverItems = this.serverItems.filter(
            (item) => item.fluidComponentReference !== "H2"
          );

          const allUniqueKeys = Array.from(allKeys);

          const displayNames = [];
          const digitsArray = [];

          await Promise.all(
            allUniqueKeys.map(async (key) => {
              const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
              const { displayName, digits } = await getDisplayName(
                "Composition",
                capitalizedKey
              );
              displayNames.push(displayName);
              digitsArray.push(digits);
            })
          );

          // Update the headers array to use display names
          this.headers = Array.from(allKeys).map((key, index) => {
            const uom = this.serverItems.find((item) => item[key + "_UOM"]);
            const name = displayNames[index]; // Use display name instead of key
            let unit = uom ? uom[key + "_UOM"] : "-";
            // Check if unit is empty or equals "data-not-available", then set it to "-"
            if (!unit || unit === "data-not-available") {
              unit = "-";
            }
            const digits = digitsArray[index] || 0;
            return {
              key: key,
              name: name,
              unit: unit,
              digits: digits,
            };
          });
          this.calculateSums();
          return response.data.data.composition;
        } else if (response && response.data && response.data.message) {
          this.timeoutMessage = response.data.message;
        } else {
          this.timeoutMessage = "Error in fetching data";
          this.sendDataToParent();
        }
      } catch (error) {
        this.timeoutMessage = error;
        this.sendDataToParent();
      } finally {
        this.loading = false;
        this.showLoaders = false;
      }
    },

    async plotChartForVariable(variable, chartIndex, composition) {
      if (!composition) {
        return;
      }

      // Find the digits value for the current variable
  const header = this.headers.find(header => header.key === variable);
  const digits = header ? header.digits : 0;

  // Filter out "H2" from the composition and keep only valid x and y values
  const filteredComposition = composition.composition
    .filter(
      step =>
        step.fluidComponentReference !== 'H2' && // Exclude "H2"
        step.fluidComponentReference &&         // Ensure x is valid
        step[variable] !== undefined &&         // Ensure y is not undefined
        step[variable] !== null                 // Ensure y is not null
    );

  // Map the filtered data for x (component reference) and y (variable value)
  const fluidComponents = filteredComposition.map(step => step.fluidComponentReference);
  const values = filteredComposition.map(step => step[variable].toFixed(digits)); // Format y values

      const trace = {
        x: fluidComponents,
        y: values,
        type: "scatter",
        mode: "lines+markers",
        name: variable,
      };

      const layout = {
        // title: {
        //   text: this.getHeaderDisplayName(variable),
        //   font: {
        //     size: 12,
        //   },
        // },
        xaxis: {
          title: {
            text: "Component",
          },
          tickangle: 45,
        },
        yaxis: {
          title: this.getHeaderDisplayName(variable),
          type: this.yAxisScale 
        },
         margin: {
          l: 50,
        },
      };
      const config = {
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: [
          "lasso2d",
          "select2d",
          "pan2d",
          "resetscale",
          "zoom",
        ],
        modeBarButtonsToAdd: [
          {
            name: "Expand",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${Expand}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.openModal.bind(this, chartIndex),
          },
          {
            name: "Download CSV",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.handleDownloadCSV.bind(this, chartIndex),
          },
        ],
      };

      this.chartData[chartIndex] = {
        trace: trace,
        layout: layout,
        config: config,
      };

      const chartRef = this.$refs["chart-" + chartIndex];
      if (chartRef && chartRef.length > 0) {
        const chartElement = chartRef[0];
        Plotly.newPlot(chartElement, [trace], layout, config);
      } else {
        return;
      }

      this.showExpandButton.splice(chartIndex, 1, true);
    },
    getHeaderDisplayName(variable) {
      const header = this.headers.find((header) => header.key === variable);
      if (header) {
        return header.unit !== "-"
          ? `${header.name} (${header.unit})`
          : header.name;
      }
      return variable;
    },

    displayValue(key, value) {
      const header = this.headers.find((header) => header.key === key);
      const digits = header ? header.digits : 0;

      if (value !== null && value !== undefined) {
        // Check if value is a string to avoid tagging decimals
        if (typeof value === 'string') {
          return value;
        } else {
          // Convert value to number and check if it's valid
          const numValue = Number(value);
          if (!isNaN(numValue)) {
            return digits > 0 ? numValue.toFixed(digits) : value;
          }
        }
      }
      return "-";
    },

    handleDownloadCSV(chartIndex) {
      this.downloadChartCSV(chartIndex);
    },

    downloadChartCSV(index) {
      const chartData = this.chartData[index];

      // Check if chart data exists
      if (chartData) {
        const fileName = `${chartData.layout.yaxis.title.text} vs ${chartData.layout.xaxis.title.text} data.csv`;

        // Prepare CSV header dynamically
        const csvHeader = [
          chartData.layout.xaxis.title.text,
          chartData.layout.yaxis.title.text,
        ];

        // Prepare CSV rows
        const csvRows = chartData.trace.x.map((xValue, i) => [
          xValue,
          chartData.trace.y[i],
        ]);

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      }
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.plotModalChart(index);
      });
    },

    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },
    plotModalChart(index) {
      const chartData = this.chartData[index];
      if (chartData) {
        const modalChartRef = this.$refs[`modal-chart-${index}`][0];
        Plotly.Plots.resize(modalChartRef);
        Plotly.newPlot(
          modalChartRef,
          [chartData.trace],
          chartData.layout,
          chartData.config
        );
      }
    },
    sendDataToParent() {
      this.$emit("data-updated", {
        testID: this.testID,
        testName: this.testName,
        sampleID: this.sampleID,
        fluidSampleID: this.fluidSampleID,
        saturationPressure: this.saturationPressure,
        saturationPressure_UOM: this.saturationPressure_UOM,
        testTemperature: this.testTemperature,
        testTemperature_UOM: this.testTemperature_UOM,
      });
    },

    downloadTableCSV() {
      // Check if table has items
      if (this.serverItems && this.serverItems.length > 0) {
        const fileName = `composition_analysis_table.csv`;

        // Prepare CSV header dynamically from table headers
        const csvHeader = this.headers.map((header) => header.name);
        const csvUnits = this.headers.map((header) => header.unit);

        // Prepare CSV rows from table data
        const csvRows = this.serverItems.map((item) =>
          this.headers.map((header) => item[header.key])
        );

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          csvUnits.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      } else {
        console.error("No data available in the table to download.");
      }
    },

    chartPlotted() {
      this.loadItems().then((ccetest) => {
        const variablesToPlot = [];
        if (this.toggleFraction) {
          variablesToPlot.push(
            "vapor_MassFraction",
            "liquid_MassFraction",
            "overall_MassFraction"
          );
        } else {
          variablesToPlot.push(
            "vapor_MoleFraction",
            "liquid_MoleFraction",
            "overall_MoleFraction"
          );
        }

        const filteredVariablesToPlot = variablesToPlot.filter((variable) => {
          return ccetest?.composition?.some(
            (item) => item[variable] !== undefined && item[variable] !== null
          );
        });

        this.hasVariablesToPlot = filteredVariablesToPlot.length > 0; // Update the flag

        if (
          JSON.stringify(filteredVariablesToPlot) !==
          JSON.stringify(this.variablesToPlot)
        ) {
          this.variablesToPlot = filteredVariablesToPlot;
        }

        filteredVariablesToPlot.forEach((variable, index) => {
          this.plotChartForVariable(variable, index, ccetest);
        });
      });
    },
  },
  mounted() {
    this.chartPlotted();
  },
};
</script>